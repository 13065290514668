import styled from "styled-components";

const FaqWrapper = styled.section`
  display: flex;
  flex-direction: column;
  padding: 2% 15% 2% 15%;
  margin: 20px auto 60px;
  background-color: #f1f7f8;
  z-index: 0;
  padding-top: 120px !important;
  margin-top: -100px !important;

  @media screen and (max-width: 425px) {
    padding: 2% 5% 2% 5%;
  }

  .question {
    display: flex;
    color: #35c4e5;
    justify-content: space-between;

    > button {
      background-color: rgba(0, 0, 0, 0);
      border: none;
      font-size: 20px;
    }
  }

  .answer {
    display: flex;

    > p {
      margin-top: 0;
      width: 2%;

      @media screen and (max-width: 1024px) {
        width: 4%;
      }
      @media screen and (max-width: 768px) {
        width: 6%;
      }
      @media screen and (max-width: 425px) {
        width: 15%;
      }
      @media screen and (max-width: 320px) {
        width: 25%;
      }
    }
  }

  .answer-hidden {
    height: 0;
    opacity: 0;
    padding: 0 !important;
    margin: 0 !important;
  }

  .answer-shown {
    height: auto;
    opacity: 1;
    transition: opacity 1s;
  }

  .question,
  .answer {
    background-color: white;
    padding: 20px;
    margin-bottom: 10px;
    border-radius: 5px;
  }

  .big-img {
    border-radius: 50% 50%;
    box-shadow: 0 0 20px #03baed;
    position: absolute;
    right: 40px;
    top: 120px;

    @media screen and (max-width: 1024px) {
      height: 90px;
    }
    @media screen and (max-width: 768px) {
      height: 60px;
    }
    @media screen and (max-width: 425px) {
      display: none;
    }
  }
  .medium-img {
    border-radius: 50% 50%;
    box-shadow: 0 0 20px #03baed;
    position: absolute;
    left: 40px;
    top: 300px;
    @media screen and (max-width: 1024px) {
      height: 60px;
    }
    @media screen and (max-width: 768px) {
      height: 40px;
    }
    @media screen and (max-width: 425px) {
      display: none;
    }
  }
  .small-img {
    border-radius: 50% 50%;
    box-shadow: 0 0 20px #03baed;
    position: absolute;
    right: 70px;
    top: 400px;
    @media screen and (max-width: 1024px) {
      height: 40px;
    }
    @media screen and (max-width: 768px) {
      height: 20px;
    }
    @media screen and (max-width: 425px) {
      display: none;
    }
  }
`;

export const FaqBanner = styled.section`
  z-index: 1;
  .faq-banner {
    margin-bottom: 20px;
    background: url(${(props) => (props.bg ? props.bg : null)});
    background-position: center center;
    padding-bottom: 45px;
    background-size: cover;
    background-repeat: no-repeat;

    @media screen and (max-width: 1024px) {
      padding-bottom: 20px;
    }

    > h2 {
      margin: 0;
      padding-top: 45px;
      font-size: 48px;
      color: white;
      text-align: center;

      @media screen and (max-width: 1024px) {
        padding-top: 20px;
      }
      @media screen and (max-width: 768px) {
        font-size: 28px;
      }
    }
    > p {
      margin: 0;
      font-size: 18px;
      color: white;
      text-align: center;

      @media screen and (max-width: 768px) {
        font-size: 16px;
      }
    }
  }
`;

export default FaqWrapper;
