import React, { useState } from 'react';
import FaqWrapper, { FaqBanner } from './faq.style';
import banner from 'common/assets/image/faq-banner.png';
import big from 'common/assets/image/phal-big.png';
import medium from 'common/assets/image/phal-medium.png';
import small from 'common/assets/image/phal-small.png';

const FaqSection = () => {
  const [openQuestions, setOpenQuestions] = useState(new Set());

  const toggleQuestion = (idx) => {
    setOpenQuestions((curr) => {
      const newSet = new Set(curr);
      if (newSet.has(idx)) newSet.delete(idx);
      else newSet.add(idx);
      return newSet;
    });
  };

  const isOpen = (idx) => openQuestions.has(idx);

  return (
    <>
      <FaqBanner bg={banner}>
        <div className="faq-banner">
          <h2>FREQUENTLY ASKED QUESTIONS</h2>
          <p>Have Any Questions?</p>
        </div>
      </FaqBanner>
      <FaqWrapper>
        <img className="big-img" src={big} />
        <img className="medium-img" src={medium} />
        <img className="small-img" src={small} />
        <div className="question" onClick={() => toggleQuestion(0)}>
          How does Free Forever really work? Is it really free forever ?
          {!isOpen(0) ? <button>&#x2C3;</button> : <button> &#x2C5;</button>}
        </div>
        {isOpen(0) ? (
          <div className="answer answer-shown">
            <p>&#x2022;</p> Yes. You can unlock 100 contacts each month. You
            don't even need a credit card to start. Just create your account and
            start prospecting
          </div>
        ) : (
          <div className="answer answer-hidden">
            <p>&#x2022;</p> Yes. You can unlock 100 contacts each month. You
            don't even need a credit card to start. Just create your account and
            start prospecting
          </div>
        )}

        <div className="question" onClick={() => toggleQuestion(1)}>
          Can I really unlock Unlimited contacts in a month ?
          {!isOpen(1) ? <button>&#x2C3;</button> : <button> &#x2C5;</button>}
        </div>
        {isOpen(1) ? (
          <div className="answer answer-shown">
            <p>&#x2022;</p> Yes. But we may have certain rate limit to ensure
            that our other customers don't suffer. If you unlock too many
            contacts too fast, you may be forced to take a little break.
          </div>
        ) : (
          <div className="answer answer-hidden">
            <p>&#x2022;</p> Yes. But we may have certain rate limit to ensure
            that our other customers don't suffer. If you unlock too many
            contacts too fast, you may be forced to take a little break.
          </div>
        )}

        <div className="question" onClick={() => toggleQuestion(2)}>
          How often do you update your database ?
          {!isOpen(2) ? <button>&#x2C3;</button> : <button> &#x2C5;</button>}
        </div>
        {isOpen(2) ? (
          <div className="answer answer-shown">
            <p>&#x2022;</p> Our contacts database has over 600 Million contacts.
            It updates every 90 days. Nothing in the system stays for more than
            90 days.
          </div>
        ) : (
          <div className="answer answer-hidden">
            <p>&#x2022;</p> Our contacts database has over 600 Million contacts.
            It updates every 90 days. Nothing in the system stays for more than
            90 days.
          </div>
        )}

        <div className="question" onClick={() => toggleQuestion(3)}>
          Which all integrations does the platform support ?
          {!isOpen(3) ? <button>&#x2C3;</button> : <button> &#x2C5;</button>}
        </div>
        {isOpen(3) ? (
          <div className="answer answer-shown">
            <p>&#x2022;</p> We have all major CRM and Marketing Automation
            Platform integrations. You can check the integrations page on the
            website to see the details.
          </div>
        ) : (
          <div className="answer answer-hidden">
            <p>&#x2022;</p> We have all major CRM and Marketing Automation
            Platform integrations. You can check the integrations page on the
            website to see the details.
          </div>
        )}

        <div className="question" onClick={() => toggleQuestion(4)}>
          How do I make payment ?
          {!isOpen(4) ? <button>&#x2C3;</button> : <button> &#x2C5;</button>}
        </div>
        {isOpen(4) ? (
          <div className="answer answer-shown">
            <p>&#x2022;</p> You can pay using Visa or Marstercard.
            Unfortunately, we don’t support AMEX yet.
          </div>
        ) : (
          <div className="answer answer-hidden">
            <p>&#x2022;</p> You can pay using Visa or Marstercard.
            Unfortunately, we don’t support AMEX yet.
          </div>
        )}
      </FaqWrapper>
    </>
  );
};

export default FaqSection;
